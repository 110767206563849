/* ********************************* */
/* Header */
/* ********************************* */

.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: rgb(255, 255, 255, 0.7);

   /* Defined because it will be sticky */
   height: 9.6rem;
   padding: 0 4.8rem;
   position: relative;
}

.logo {
   height: 11rem;
   margin-top: 2rem;
}

.welcome-bg {
   z-index: inherit;
   background-image: url(../img/4.jpg);
   background-size: 100%;
   background-repeat: no-repeat;
   background-attachment: fixed;
}

/* ********************************* */
/* Nav Section */
/* ********************************* */

.main-nav-list {
   list-style: none;
   display: flex;
   gap: 4.8rem;
   align-items: center;
}

.main-nav-link:link,
.main-nav-link:visited {
   display: inline-block;
   text-decoration-color: rgba(0, 0, 0, 0);
   color: #333;
   font-weight: 600;
   font-size: 2.4rem;
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
   padding: 1.2rem 2.4rem;
   border-radius: 9px;
   color: #fff;
   background-color: #f1e5cd;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
   background-color: #f1e5cd;
}

.btn-mobile-nav {
   border: none;
   background: none;
   cursor: pointer;
   display: none;
}

.icon-mobile-nav {
   height: 4.8rem;
   width: 4.8rem;
   color: #333;
}

.icon-mobile-nav[name="close-outline"] {
   display: none;
}

/* Sticky Nav */

.sticky .header {
   position: fixed;
   top: 0;
   bottom: 0;
   width: 100%;
   height: 8rem;
   padding-top: 0;
   padding-bottom: 0;
   background-color: rgba(255, 255, 255, 0.9);
   z-index: 999;
   box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.sticky .section-hero {
   margin-top: 9.6rem;
}

.dropdown {
   float: left;
   overflow: hidden;
}

.dropdown .dropbtn {
   font-size: 2.4rem;
   font-weight: 600;
   border: none;
   outline: none;
   text-align: center;
   color: black;

   background-color: inherit;
   font-family: inherit;
   margin: 0;

   text-decoration: underline;
   text-decoration-color: rgba(0, 0, 0, 0);
}

.navbar a:hover,
.dropdown:hover .dropbtn {
   cursor: pointer;
}

.dropdown-content {
   display: none;
   position: absolute;
   background-color: #f9f9f9;
   min-width: 160px;
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
   z-index: 1;
   font-size: 1.6rem;
}

.dropdown-content a {
   float: none;
   color: black;
   padding: 12px 16px;
   text-decoration: none;
   display: block;
   text-align: left;
}

.dropdown-content a:hover {
   background-color: #ddd;
}

.dropdown:hover .dropdown-content {
   display: block;
}

/* ********************************* */
/* Welcome Section */
/* ********************************* */

.welcome {
   margin: 0 auto;
   text-align: center;
   padding-top: 25vh;
   position: relative;
   height: 80vh;
}

.welcome-cta {
   display: flex;
   flex-direction: column;
   gap: 3.2rem;
   position: absolute;
   top: 10%;
   right: 10%;
}

.cta:link,
.cta:visited {
   text-decoration: none;
   background-color: #bbb;
   padding: 3.2rem 4.8rem;
   font-size: 3rem;
   font-weight: 700;
   width: 100%;
   transition: all 0.3s;
}

.cta:hover,
.cta:active {
   background-color: white;
   transform: translateY(-5px);
}

.blue-box {
   color: #02396b;
   box-shadow: -1.2rem 0.8rem 0 #02396b;
}

.red-box {
   color: #590812;
   box-shadow: -1.2rem 0.8rem 0 #590812;
}

.about-box {
   position: absolute;
   top: 50%;
   right: 5%;

   background-color: rgba(253, 252, 251, 0.9);
   padding: 1rem 2.4rem 2.4rem;
   max-width: 170ch;
   border-radius: 9px;
}

@media (max-width: 1550px) and (max-height: 740px) {
   .welcome {
      height: 105vh;
   }
   .about-box {
      max-width: auto;
      padding: 1rem 2.4rem 0;
      margin: 3rem;
   }
   .upcoming-shoots {
      margin-top: 4rem;
   }
}

.about-box-heading {
   padding-bottom: 1.2rem;
   font-size: 4.4rem;
   line-height: 1.2;
   text-align: left;
}

.about-box p {
   font-size: 2rem;
   text-align: left;
   text-indent: 20px;
   padding-bottom: 1rem;
}

/* ********************************* */
/* Upcoming Section */
/* ********************************* */

.upcoming-shoots {
   background-color: #fdfcfb;
   padding-bottom: 4.8rem;
}

.upcoming-shoot-list {
   list-style: none;
   font-size: 2.4rem;
}

.upcoming-shoot-item {
   padding-bottom: 2.4rem;
}

/* ********************************* */
/* Gallery Section */
/* ********************************* */

.gallery {
   background-color: #fdfcfb;
   padding-bottom: 4.8rem;
}

.slideshow {
   position: relative;
   max-width: 60rem;
}

/* Hide the images by default */
.mySlides {
   display: none;
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
   cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
   cursor: pointer;
   position: absolute;
   top: 40%;
   width: auto;
   padding: 16px;
   margin-top: -50px;
   color: white;
   font-weight: bold;
   font-size: 20px;
   border-radius: 0 3px 3px 0;
   user-select: none;
   -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
   right: 0;
   transform: translateX(-75%);
   border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
   background-color: rgba(0, 0, 0, 0.8);
}

.row:after {
   content: "";
   display: table;
   clear: both;
}

/* Six columns side by side */
.column {
   float: left;
   width: 16.66%;
}

/* Add a transparency effect for thumnbail images */
.demo {
   opacity: 0.6;
}

.active,
.demo:hover {
   opacity: 1;
}

/* ********************************* */
/* Shoot Page Section */
/* ********************************* */

.bg-shoot-info {
   background-image: url(../img/PageBG.jpg);
   background-attachment: fixed;
   background-size: cover;
}

.club-shoot-info {
   margin: 6.4rem 2.4rem;
   background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
   padding-bottom: 4rem;
}

.shoot-heading:link,
.shoot-heading:visited {
   display: inline-block;
   font-size: 3.6rem;
   line-height: 1.2;
   color: black;
   font-weight: 600;
   height: 9rem;
}

.shoot-container-grid {
   display: grid;
   row-gap: 9.6rem;
   column-gap: 6.4rem;
   margin-bottom: 9.6rem;
   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.shoot-heading:hover,
.shoot-heading:active {
   color: #022647;
}

.club-shoot-list {
   padding-top: 2rem;
   list-style: none;
   font-size: 2.4rem;
}

/* ********************************* */
/* Club Section */
/* ********************************* */

.club-grid {
   display: grid;
   row-gap: 9.6rem;
   column-gap: 6.4rem;
   margin-bottom: 9.6rem;
   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.club-heading {
   font-size: 2.4rem;
   line-height: 1.2;
   font-weight: 600;
   height: 7rem;
   color: #022647;
}

.club-info {
   font-size: 2rem;
   padding-bottom: 1.2rem;
}

.club-info-website:link,
.club-info-website:visited {
   font-size: 2rem;
   text-decoration: none;
}

.club-info-website:hover,
.club-info-website:active {
   color: #022647;
   text-decoration: underline;
}

/* ********************************* */
/* Club Resource Section */
/* ********************************* */

.club-shoot-info h3 {
   font-size: 3rem;
   padding-bottom: 2.4rem;
}

.club-resource-container {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 4.4rem;
   text-align: center;
   line-height: 1.1;
}

.club-resource-link:link,
.club-resource-link:visited {
   background-color: #022647;
   box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.1);
   padding: 1.2rem 2.4rem;
   border-radius: 11px;
   color: #e6e9ed;
   font-size: 2.4rem;
   transition: all 0.3s;
}

.club-resource-link:hover,
.club-resource-link:active {
   background-color: #02396b;
   transform: translateY(-5px);
}

/* ********************************* */
/* Meeting Minutes Section */
/* ********************************* */

.meeting-minutes-container {
   padding: 0 4.8rem;
}

.meeting-header {
   font-size: 3rem;
   line-height: 1.2;
   margin-bottom: 4rem;
}

.meeting-minute-img {
   width: 100%;
}

.mobile {
   text-align: center;
}

/* ********************************* */
/* Governing Documents Section */
/* ********************************* */

.governing-documents-container {
   padding: 0 4.8rem;
}

.governing-header {
   font-size: 3rem;
   line-height: 1.2;
   margin-bottom: 4rem;
}

.iframe-fallback:link,
.iframe-fallback:visited {
   font-size: 2rem;
}

.iframe-fallback h3 {
   padding-bottom: 2rem;
}

/* ********************************* */
/* Financial Reports Section */
/* ********************************* */

.financial-reports-container {
   padding: 0 4.8rem;
}

.financial-header {
   font-size: 3rem;
   line-height: 1.2;
   margin-bottom: 4rem;
}

.financial-report-img {
   width: 100%;
}

/* ********************************* */
/* HOF Section */
/* ********************************* */

.hof-section {
   margin: 6.4rem 2.4rem;
   background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
   padding-bottom: 4rem;
}

.hof-section h3 {
   text-align: center;
   font-size: 3rem;
   padding-bottom: 2.4rem;
}

/* ********************************* */
/* Footer Section */
/* ********************************* */

.footer {
   background-color: #fdfcfb;
   margin-top: auto;
}

.copyright {
   padding: 0.8rem;
   text-align: center;
   font-size: 2rem;
}

.created-by:link,
.created-by:visited {
   color: #022647;
   transition: all 0.3s;
}

.created-by:hover,
.created-by:active {
   color: #022647;
   font-size: 2.2rem;
}

/* SVG icon styles for improved mobile navigation */
.close-icon {
   display: none;
}

.nav-open .close-icon {
   display: block;
   position: fixed;
   top: 4%;
   right: 10%;
}

.nav-open .menu-icon {
   display: none;
}

/* Style for dropdown SVG icons */
.dropdown-icon {
   display: inline-block;
   vertical-align: middle;
   margin-left: 4px;
   color: #333;
}

/* Ensure mobile nav SVG icons maintain original styling */
.icon-mobile-nav {
   height: 4.8rem;
   width: 4.8rem;
   color: #333;
}

/* 
FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
 */
