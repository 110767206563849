.header {
  height: 9.6rem;
  background-color: #ffffffb3;
  justify-content: space-between;
  align-items: center;
  padding: 0 4.8rem;
  display: flex;
  position: relative;
}

.logo {
  height: 11rem;
  margin-top: 2rem;
}

.welcome-bg {
  z-index: inherit;
  background-image: url("4.e32bd1f0.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
}

.main-nav-list {
  align-items: center;
  gap: 4.8rem;
  list-style: none;
  display: flex;
}

.main-nav-link:link, .main-nav-link:visited {
  color: #333;
  font-size: 2.4rem;
  font-weight: 600;
  text-decoration-color: #0000;
  display: inline-block;
}

.main-nav-link.nav-cta:link, .main-nav-link.nav-cta:visited {
  color: #fff;
  background-color: #f1e5cd;
  border-radius: 9px;
  padding: 1.2rem 2.4rem;
}

.main-nav-link.nav-cta:hover, .main-nav-link.nav-cta:active {
  background-color: #f1e5cd;
}

.btn-mobile-nav {
  cursor: pointer;
  background: none;
  border: none;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

.sticky .header {
  width: 100%;
  height: 8rem;
  z-index: 999;
  background-color: #ffffffe6;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  box-shadow: 0 1.2rem 3.2rem #00000008;
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  text-align: center;
  color: #000;
  background-color: inherit;
  border: none;
  outline: none;
  margin: 0;
  font-family: inherit;
  font-size: 2.4rem;
  font-weight: 600;
  text-decoration: underline #0000;
}

.navbar a:hover, .dropdown:hover .dropbtn {
  cursor: pointer;
}

.dropdown-content {
  min-width: 160px;
  z-index: 1;
  background-color: #f9f9f9;
  font-size: 1.6rem;
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px #0003;
}

.dropdown-content a {
  float: none;
  color: #000;
  text-align: left;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.welcome {
  text-align: center;
  height: 80vh;
  margin: 0 auto;
  padding-top: 25vh;
  position: relative;
}

.welcome-cta {
  flex-direction: column;
  gap: 3.2rem;
  display: flex;
  position: absolute;
  top: 10%;
  right: 10%;
}

.cta:link, .cta:visited {
  width: 100%;
  background-color: #bbb;
  padding: 3.2rem 4.8rem;
  font-size: 3rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .3s;
}

.cta:hover, .cta:active {
  background-color: #fff;
  transform: translateY(-5px);
}

.blue-box {
  color: #02396b;
  box-shadow: -1.2rem .8rem #02396b;
}

.red-box {
  color: #590812;
  box-shadow: -1.2rem .8rem #590812;
}

.about-box {
  max-width: 170ch;
  background-color: #fdfcfbe6;
  border-radius: 9px;
  padding: 1rem 2.4rem 2.4rem;
  position: absolute;
  top: 50%;
  right: 5%;
}

@media (max-width: 1550px) and (max-height: 740px) {
  .welcome {
    height: 105vh;
  }

  .about-box {
    max-width: auto;
    margin: 3rem;
    padding: 1rem 2.4rem 0;
  }

  .upcoming-shoots {
    margin-top: 4rem;
  }
}

.about-box-heading {
  text-align: left;
  padding-bottom: 1.2rem;
  font-size: 4.4rem;
  line-height: 1.2;
}

.about-box p {
  text-align: left;
  text-indent: 20px;
  padding-bottom: 1rem;
  font-size: 2rem;
}

.upcoming-shoots {
  background-color: #fdfcfb;
  padding-bottom: 4.8rem;
}

.upcoming-shoot-list {
  font-size: 2.4rem;
  list-style: none;
}

.upcoming-shoot-item {
  padding-bottom: 2.4rem;
}

.gallery {
  background-color: #fdfcfb;
  padding-bottom: 4.8rem;
}

.slideshow {
  max-width: 60rem;
  position: relative;
}

.mySlides {
  display: none;
}

.cursor {
  cursor: pointer;
}

.prev, .next {
  cursor: pointer;
  width: auto;
  color: #fff;
  user-select: none;
  border-radius: 0 3px 3px 0;
  margin-top: -50px;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 40%;
}

.next {
  border-radius: 3px 0 0 3px;
  right: 0;
  transform: translateX(-75%);
}

.prev:hover, .next:hover {
  background-color: #000c;
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.column {
  float: left;
  width: 16.66%;
}

.demo {
  opacity: .6;
}

.active, .demo:hover {
  opacity: 1;
}

.bg-shoot-info {
  background-image: url("PageBG.e436580c.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.club-shoot-info {
  background-image: linear-gradient(#fffc, #fffc);
  margin: 6.4rem 2.4rem;
  padding-bottom: 4rem;
}

.shoot-heading:link, .shoot-heading:visited {
  color: #000;
  height: 9rem;
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1.2;
  display: inline-block;
}

.shoot-container-grid {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 9.6rem 6.4rem;
  margin-bottom: 9.6rem;
  display: grid;
}

.shoot-heading:hover, .shoot-heading:active {
  color: #022647;
}

.club-shoot-list {
  padding-top: 2rem;
  font-size: 2.4rem;
  list-style: none;
}

.club-grid {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 9.6rem 6.4rem;
  margin-bottom: 9.6rem;
  display: grid;
}

.club-heading {
  height: 7rem;
  color: #022647;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.2;
}

.club-info {
  padding-bottom: 1.2rem;
  font-size: 2rem;
}

.club-info-website:link, .club-info-website:visited {
  font-size: 2rem;
  text-decoration: none;
}

.club-info-website:hover, .club-info-website:active {
  color: #022647;
  text-decoration: underline;
}

.club-shoot-info h3 {
  padding-bottom: 2.4rem;
  font-size: 3rem;
}

.club-resource-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 4.4rem;
  line-height: 1.1;
  display: flex;
}

.club-resource-link:link, .club-resource-link:visited {
  color: #e6e9ed;
  background-color: #022647;
  border-radius: 11px;
  padding: 1.2rem 2.4rem;
  font-size: 2.4rem;
  transition: all .3s;
  box-shadow: 0 2.4rem 4.8rem #0000001a;
}

.club-resource-link:hover, .club-resource-link:active {
  background-color: #02396b;
  transform: translateY(-5px);
}

.meeting-minutes-container {
  padding: 0 4.8rem;
}

.meeting-header {
  margin-bottom: 4rem;
  font-size: 3rem;
  line-height: 1.2;
}

.meeting-minute-img {
  width: 100%;
}

.mobile {
  text-align: center;
}

.governing-documents-container {
  padding: 0 4.8rem;
}

.governing-header {
  margin-bottom: 4rem;
  font-size: 3rem;
  line-height: 1.2;
}

.iframe-fallback:link, .iframe-fallback:visited {
  font-size: 2rem;
}

.iframe-fallback h3 {
  padding-bottom: 2rem;
}

.financial-reports-container {
  padding: 0 4.8rem;
}

.financial-header {
  margin-bottom: 4rem;
  font-size: 3rem;
  line-height: 1.2;
}

.financial-report-img {
  width: 100%;
}

.hof-section {
  background-image: linear-gradient(#fffc, #fffc);
  margin: 6.4rem 2.4rem;
  padding-bottom: 4rem;
}

.hof-section h3 {
  text-align: center;
  padding-bottom: 2.4rem;
  font-size: 3rem;
}

.footer {
  background-color: #fdfcfb;
  margin-top: auto;
}

.copyright {
  text-align: center;
  padding: .8rem;
  font-size: 2rem;
}

.created-by:link, .created-by:visited {
  color: #022647;
  transition: all .3s;
}

.created-by:hover, .created-by:active {
  color: #022647;
  font-size: 2.2rem;
}

.close-icon {
  display: none;
}

.nav-open .close-icon {
  display: block;
  position: fixed;
  top: 4%;
  right: 10%;
}

.nav-open .menu-icon {
  display: none;
}

.dropdown-icon {
  vertical-align: middle;
  color: #333;
  margin-left: 4px;
  display: inline-block;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

/*# sourceMappingURL=index.265152df.css.map */
